import domReady from '@roots/sage/client/dom-ready';
import jQuery from 'jquery';
import AOS from 'aos';

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  jQuery(function () {
    jQuery("#mobileMenuTrigger").on( "click", function() {
      jQuery(this).toggleClass("open");
      jQuery("#mobileMenu").toggleClass("open");
    });
    jQuery("main").on( "click", function() {
      jQuery("#mobileMenuTrigger.open").toggleClass("open");
      jQuery("#mobileMenu").removeClass("open");
    });
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

AOS.init();